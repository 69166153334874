<template>
	  <div class="ui grid m-0">
        <div class="six wide column">
         <div class="login-container">
             <div class="mt-5">
                    <h1 class="header-info">SYSTEME de GESTION</h1>
                    <h2 class="sub-header-info">des dossiers d’expertise</h2>
             </div>
                <div class="ui grid mt-5">
                    <div class="row centered">
                        <div class="ten wide column">
                            <div class="ui form" @keydown.enter="onLoginClick" >
                                <div class="field">
                                    <label class="login-label">Identifiant</label>
                                    <input type="text" class="login-input" v-model="username"  placeholder="Username">
                                </div>
                                <div class="field">
                                    <label class="login-label">Mot de passe</label>
                                    <input type="password" v-model="password" class="login-input" placeholder="Password">
                                </div>
                                <div class="field">
                                    <button class="ui fluid button login-btn" @click="onLoginClick">LOGIN</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'login',
		data() {
			return {
				username: '',
				password: ''
			}
		},
		methods: {
            onLoginClick() {
                log("`onLoginClick`");
                this.$store
                    .dispatchAsync(this.$SHARED.services.login, {
                        username: this.username,
                        password: this.password,
                        version: this.$store.state.config.version
                    })
                    .then(data => {
                            this.$notify({
                                group: "user-message",
                                type: "success", // warn , error, success, info
                                text: "Welcome " + data.username + " !"
                            });
                            this.$router.push({name:"home"});
                        },
                        code => {
                            this.$notify({
                                group: "user-message",
                                type: "error", // warn , error, success, info
                                text: this.$SHARED.messages[code]
                            });
                        }
                    );
            }

        },
		beforeCreate () {
			document.body.classList.add('b-style');
		},
		mounted() {
			
		}
	}

</script>

<style>

	.b-style {
		background-image: url('../assets/images/bg.jpg');
     	background-size: cover;
	}

	/* body > .grid {
		height: 100%;
	} */

	#login {
		margin-top: 100px;
	}

	/* .column {
		max-width: 450px;
	} */

</style>